import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function CreatePost() {
  const navigate = useNavigate();
  const { profile } = useAuth();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    authorName: profile.name,
    authorEmail: profile.email,
    topic: '',
    socialMediaHandle: '',
    profileImage: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({ ...formData, profileImage: file });
  };

  const handleDescriptionChange = (value) => {
    setFormData({ ...formData, description: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const postData = new FormData();
    for (const key in formData) {
      postData.append(key, formData[key]);
    }

    try {
      await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/blog/upload`, postData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      toast.success("Blog submitted successfully! It will be live upon approval by the admin.");
      navigate('/');
      console.log('Blog uploaded successfully');
      // Add any additional success handling or redirect as needed
    } catch (error) {
      console.error('Error uploading blog:', error);
      // Add error handling and display to the user
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-blue-900">
      <div className="max-w-md w-full p-4 bg-white text-gray-800 rounded-md shadow-md">
        <h2 className="text-3xl font-semibold mb-4">Upload a Blog</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="text"
            name="title"
            placeholder="Title"
            onChange={handleInputChange}
            required
            className="p-3 border rounded w-full focus:outline-none focus:border-blue-500 bg-gray-100"
          />
          <ReactQuill

            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleDescriptionChange}
            required
            className="p-3 border rounded w-full focus:outline-none focus:border-blue-500 bg-gray-100"
          />
          <input
            type="text"
            name="authorEmail"
            placeholder="Author's Email"
            onChange={handleInputChange}
            required
            value={profile.email}
            readOnly
            className="p-3 border rounded w-full bg-gray-200"
          />
          <input
            type="text"
            name="authorName"
            placeholder="Author's Name"
            onChange={handleInputChange}
            required
            value={profile.name}
            readOnly
            className="p-3 border rounded w-full bg-gray-200"
          />
          <input
            type="text"
            name="topic"
            placeholder="Topic"
            onChange={handleInputChange}
            required
            className="p-3 border rounded w-full focus:outline-none focus:border-blue-500 bg-gray-100"
          />
          <input
            type="text"
            name="socialMediaHandle"
            placeholder="Social Media Handle"
            onChange={handleInputChange}
            required
            className="p-3 border rounded w-full focus:outline-none focus:border-blue-500 bg-gray-100"
          />
          <input
            type="file"
            name="profileImage"
            accept="image/*"
            onChange={handleFileChange}
            required
            className="p-3 border rounded w-full focus:outline-none focus:border-blue-500 bg-gray-100"
          />
          {isSubmitting ? (
            <div className="text-center text-gray-400">Uploading...</div>
          ) : (
            <button
              type="submit"
              className="bg-blue-500 text-white rounded-full p-3 w-full hover:bg-blue-600 focus:outline-none"
              disabled={isSubmitting}
            >
              Upload
            </button>
          )}
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreatePost;
