import React, { useState, useEffect } from 'react';
import axios from 'axios';

const DeleteEvent = () => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/displayEvent`);
                setEvents(response.data);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    const deleteEvent = async (eventId) => {
        try {
            await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/deleteEvent/${eventId}`);
            // Update the events list after deletion
            const updatedEvents = events.filter(event => event._id !== eventId);
            setEvents(updatedEvents);
            // Show a success message or perform other actions upon successful deletion
        } catch (error) {
            console.error('Error deleting event:', error);
            // Handle error scenario as needed
        }
    };

    const openDescriptionModal = (event) => {
        setSelectedEvent(event);
    };

    const closeDescriptionModal = () => {
        setSelectedEvent(null);
    };

    return (
        <div className="max-w-3xl mx-auto p-4 text-gray-800">
            {events.map((event) => (
                <article key={event._id} className="flex bg-white transition hover:shadow-xl dark:bg-gray-900 dark:shadow-gray-800/25 mb-6">
              

                    <div className="hidden sm:block sm:basis-56">
                        <img
                            alt={event.title}
                            src={`data:image/jpeg;base64,${event.eventImage}`}
                            className="aspect-square h-full w-full object-cover"
                        />
                    </div>

                    <div className="flex flex-1 flex-col justify-between">
                        <div className="border-s border-gray-900/10 dark:border-white/10 p-4 dark:text-white sm:border-l-transparent sm:p-6">
                            <a href="#">
                                <h3 className="font-bold uppercase text-gray-900 dark:text-white">
                                    {event.title}
                                </h3>
                            </a>

                            <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-700 dark:text-gray-200">
                                {event.description.substring(0, 120)}...
                            </p>

                            <p className="mt-2 line-clamp-3 text-sm/relaxed text-gray-700 dark:text-gray-200">
                            Event Date- {event.date}
                            </p>
                        </div>

                        <div className="sm:flex sm:items-end sm:justify-end">
                            <a
                                href="#"
                                onClick={() => openDescriptionModal(event)}
                                className="block bg-yellow-400 px-5 py-3 text-center text-xs font-bold uppercase text-gray-900 transition hover:bg-yellow-500"
                            >
                                Read 
                            </a>
                            <button
                                onClick={() => deleteEvent(event._id)}
                                className="ml-2 block bg-red-500 px-5 py-3 text-center text-xs font-bold uppercase text-white transition hover:bg-red-600"
                            >
                                Reject
                            </button>
                           
                        </div>
                    </div>
                </article>
            ))}

            {selectedEvent && (
                <div className="fixed inset-0 z-10 overflow-y-auto bg-black bg-opacity-75 flex items-center justify-center">
                    <div className="bg-white p-6 dark:bg-gray-900 dark:text-white max-w-md w-full rounded-lg shadow-lg">
                        <h2 className="text-xl font-bold mb-4">{selectedEvent.title}</h2>
                        <p className="text-sm text-gray-700 dark:text-gray-200">{selectedEvent.description}</p>
                        <button
                            onClick={closeDescriptionModal}
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DeleteEvent;
