import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

const Dashboard = () => {
  const { isLoggedIn, profile, logOut } = useAuth();
  const [userBlogs, setUserBlogs] = useState([]);

  useEffect(() => {
    const fetchUserBlogs = async () => {
      try {
        if (isLoggedIn && profile && profile.email) {
          const response = await axios.get(
            `${process.env.REACT_APP_BACKEND_URL}/api/v1/blog/user/${profile.email}`
          );
          setUserBlogs(response.data);
        }
      } catch (error) {
        console.error('Error fetching user blogs:', error);
      }
    };

    fetchUserBlogs();
  }, [isLoggedIn, profile]);

  if (!profile) {
    return null;
  }

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="bg-gradient-to-r from-indigo-700 to-purple-500 min-h-screen p-8 text-white font-sans">
      <div className="max-w-8xl mx-auto bg-[#0b132e] rounded-md p-8 shadow-lg sm:p-4">

        <div className="overflow-hidden rounded-md border border-stroke bg-[#334155] shadow-default dark:border-strokedark">

          <div className="px-4 pb-6 text-center lg:pb-8 xl:pb-11.5">
            <div className="relative flex flex-col items-center justify-center mx-auto mt-8 sm:mt-0 h-44 w-full max-w-44 rounded-md bg-white/20 p-3 sm:p-2 backdrop-blur-sm">
              <div className="relative overflow-hidden rounded-md">
                <img className="w-full h-full object-cover rounded-md" src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR0cYTihODeLNTSImny5ABxWFHWZZ2D6PxD6JjdH8lCvLty9NQ__uQblYqMsRw8V-SWpt8&usqp=CAU' alt="profile" />
              </div>
              <div className="mt-3 text-center">
                <h3 className="mb-1.5 text-2xl font-semibold dark:text-white">
                  {profile.name}
                </h3>
                <p className="font-medium">{profile.email}</p>
              </div>
            </div>

            <div className="mt-4">
              <div className="mx-auto mt-4.5 mb-5.5 grid grid-cols-1 sm:grid-cols-3 rounded-md border border-stroke py-2.5 shadow-1 dark:border-strokedark dark:bg-[#37404F]">
                <div className="flex flex-col items-center justify-center gap-1 border-r border-stroke px-4 dark:border-strokedark xsm:flex-row">
                  <span className="font-semibold dark:text-white">
                    {userBlogs.length}
                  </span>
                  <span className="text-sm">Posts</span>
                </div>
                <div className="flex flex-col items-center justify-center gap-1 border-r border-stroke px-4 dark:border-strokedark xsm:flex-row">
                  <span className="font-semibold dark:text-white">
                    129K
                  </span>
                  <span className="text-sm">Followers</span>
                </div>
                <div className="flex flex-col items-center justify-center gap-1 px-4 xsm:flex-row">
                  <span className="font-semibold dark:text-white">
                    2K
                  </span>
                  <span className="text-sm">Following</span>
                </div>
              </div>

              <div className="mx-auto max-w-full sm:max-w-180">
                <h4 className="font-semibold dark:text-white text-2xl mb-2">About Me</h4>
                <p className="mt-4.5 text-gray-300">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque posuere fermentum urna...
                </p>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-4xl font-bold mb-6 text-white text-center">Your Blogs</h2>

        {userBlogs.map((blog) => (
          <div key={blog._id} className="mb-6 p-6 bg-[#334155] rounded-md shadow-md sm:p-4">
            <div className="dark:bg-gray-800 dark:text-white rounded-md">
              <div className="container grid grid-cols-1 sm:grid-cols-12 mx-auto rounded-md dark:bg-gray-900">
                <img
                  className="bg-no-repeat bg-cover dark:bg-gray-700 col-span-full h-full lg:col-span-4 rounded-md"
                  src={`data:image/jpeg;base64,${blog.profileImage}`}
                ></img>
                <div className="flex flex-col p-6 col-span-full row-span-full lg:col-span-8 lg:p-10">
                  <div className="flex justify-start">
                    <span className="px-2 py-1 text-xs rounded-full dark:bg-blue-400 dark:text-gray-900">
                      {blog.topic}
                    </span>
                  </div>

                  <h1 className="text-4xl font-bold">{blog.title}</h1>
                  <span className={`px-2 py-1 text-sm ${blog.status === 'approved' ? 'text-green-500' : 'text-red-500'}`}>
                    Your Blog Status→ {blog.status}
                  </span>
                  <p className="flex-1 pt-2">{blog.description.substring(0, 400)}...</p>
                  <Link
                    to={`/blog/${blog.title}`}
                    className="inline-flex items-center pt-2 pb-6 space-x-2 text-sm text-blue-400 hover:underline"
                  >
                    <span>Read more</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 h-4"
                    >
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                  <div className="hidden sm:flex justify-between pt-2">
                    <div className="flex space-x-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-5 h-5 dark:text-gray-400"
                      >
                        <path
                          fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                      <span className="self-center text-sm">by {blog.authorName} (you) on {formatDate(blog.createdAt)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;

