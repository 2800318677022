import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import 'react-toastify/dist/ReactToastify.css';
import HomePage from './Pages/HomePage/HomePage';
import AboutPage from './Pages/AboutPage';
import BlogPage from './Pages/BlogPage';
import CreatePost from './Components/CreatePost';
import Dashboard from './Pages/Dashboard/Dashboard';
import Admin from './Pages/Admin/Admin';
import BlogDetails from './Pages/BlogDetails';
import NotFound from './Pages/NotFound';
import './App.css';
import './index.css';
import './loader.css';
import { Analytics } from '@vercel/analytics/react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import alanBtn from '@alan-ai/alan-sdk-web';
function App() {
  const [loading, setLoading] = useState(true);
  const [cookies, setCookie] = useCookies(['betaAlert']);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoading(false);
      notifySlowNetwork();
    }, 2000);

    return () => clearTimeout(delay);
  }, []);

  const notifySlowNetwork = () => {
    if (!cookies.betaAlert) {
      toast.warning(
        <div className="beta-alert">
          <p className="alert-text">
            <b>🚧 Under Construction Alert!🛠️</b> <br /> CodeINBlogs is in its development stage. Expect occasional bugs, but enjoy the beta version of our platform!
          </p>
          <button className="remove-alert" onClick={() => removeBetaAlert()}>X</button>
        </div>,
        {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false,
        }
      );
    }
  };

  const handlePopupClose = () => {
    setCookie('popupShown', true, { path: '/' });
  };
  const removeBetaAlert = () => {
    setCookie('betaAlert', true, { path: '/' });
    toast.dismiss(); // Close the toast
  };

  useEffect(() => {
    alanBtn({
      key: 'a38a717d8fa9fc008d154bdeac010da42e956eca572e1d8b807a3e2338fdd0dc/stage',
      onCommand: (commandData) => {
        if (commandData.command === 'go:back') {
          // Call the client code that will react to the received command
        }
      },
    });
  }, []);


  return (
    <div className="app-container">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/postBlog" element={<CreatePost />} />
        <Route path="/userdashboard" element={<Dashboard />} />
        <Route path="/admindashboard" element={<Admin />} />
        <Route path="blog/:id" element={<BlogDetails />} />
        <Route path="*" element={<NotFound />} />      </Routes>

      {/* <div className={`popup ${cookies.popupShown ? 'hidden' : 'visible'}`}>
        <div className="popup-content">
          <p>🚧 Under Construction Alert! 🛠️ CodeINBlogs is in its development stage.</p>
          <p>Expect occasional bugs, but enjoy the beta version of our platform! Your feedback is invaluable. 🐞</p>
          <button onClick={handlePopupClose}>Got it!</button>
        </div>
      </div> */}

      <ToastContainer />
      <Analytics />
      <SpeedInsights />

      {loading && (
        <div className="loader-overlay">
          <div className="loader"></div>
        </div>
      )}
    </div>
  );
}

export default App;