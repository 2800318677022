import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSpring, animated } from 'react-spring';
import { useGesture } from 'react-use-gesture';



const HeroPage = () => {
    const { isLoggedIn } = useAuth();
    const [communitys, setcommunitys] = useState([]);

    const [communityIndex, setCommunityIndex] = useState(0);

    const navigate = useNavigate();
    const creatPost = () => {
        if (isLoggedIn) {
            navigate("/postBlog");
        }
        else {
            toast.error("Please login before");
        }

    }


    const [currentCommunityIndex, setCurrentCommunityIndex] = useState(0);

    useEffect(() => {
        const isMobileView = window.innerWidth <= 768; // Adjust the threshold as needed

        if (isMobileView) {
            const interval = setInterval(() => {
                // Increment the current community index
                setCurrentCommunityIndex((prevIndex) => (prevIndex + 1) % communitys.length);
            }, 2000); // Change the duration as needed

            return () => clearInterval(interval); // Cleanup interval on component unmount
        }
    }, [communitys.length]);



    useEffect(() => {
        // Fetch communitys from the server when the component mounts
        const fetchcommunitys = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/displayCommunityImage`);
                setcommunitys(response.data);
            } catch (error) {
                console.error('Error fetching communitys:', error);
            }
        };

        fetchcommunitys();
    }, []);
    return (
        <div>
            <div className='flex flex-col text-white gap-8'>
                <div className='text-center'>

                    <h1 className='text-6xl mt-20'>Powerful Platform & Community for <br />Developer.</h1>
                </div>
                <div className='text-center'>
                    <p>"Empower Your Code Journey and Ignite the CodeINBlogs <br />Revolution to Forge Your Code Destiny!"</p>
                </div>
                <div className='flex justify-center gap-4 text-xs'>
                    <div>
                        <button className='border border-black rounded-full px-5 py-3 bg-gradient-to-r from-purple-800 to-black'
                            onClick={creatPost}>START BLOGGING</button>

                    </div>
                    <div className='border border-white rounded-full px-5 py-3'>
                        <button>EXPLORE NOW
                        </button>
                    </div>


                </div>
                <div className='flex flex-col items-center justify-center'>
                    <div>

                        <p className='text-[#618ADC]'>Our Community & Certificate Partners</p>
                    </div>
                    <div className='flex block md:hidden'>
                        {communitys.map((community, index) => (
                            <div
                                key={community._id}
                                className={`p-4 ${index === currentCommunityIndex ? 'fadeIn' : 'hidden'}`}
                                style={{
                                    animationDelay: `${index * 0.5}s`, // Adjust the delay for a staggered effect
                                }}
                            >
                                <img
                                    src={`data:image/jpeg;base64,${community.communityImage}`} // Adjust the MIME type as needed
                                    alt="community"
                                    className="w-32 h-20"
                                />
                            </div>
                        ))}
                    </div>
                    <div className='md:flex hidden'>
                        {communitys.map((community) => (
                            <div key={community._id} className="p-4">
                                <img
                                    src={`data:image/jpeg;base64,${community.communityImage}`} // Adjust the MIME type as needed
                                    alt="community"
                                    className="w-24 h-12"
                                />
                            </div>
                        ))}
                    </div>

                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default HeroPage