import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import DeleteEvent from './DeleteEvent';
import AdminPanel from './AdminPanel';
import CreateTestimonials from './CreateTestimonials';
import AddTeamMember from './AddTeamMember';


const Admin = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        title: '',
        description: '',
        speakerName: '',
        topic: '',
        eventLink: '',
        eventImage: null,
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, eventImage: file });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const postData = new FormData();
        for (const key in formData) {
            postData.append(key, formData[key]);
        }

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/upload`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            navigate("/");
            console.log('Event uploaded successfully');
        } catch (error) {
            console.error('Error uploading blog:', error);
        }
    };

    const [formData1, setFormData1] = useState({
        communityImage: null,
    });

    const handleFileChange1 = (e) => {
        const file = e.target.files[0];
        setFormData1({ ...formData1, communityImage: file });
    };

    const handleSubmit1 = async (e) => {
        e.preventDefault();

        const postData = new FormData();
        for (const key in formData1) {
            postData.append(key, formData1[key]);
        }

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/communityUpload`, postData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log('Community uploaded successfully');
        } catch (error) {
            console.error('Error uploading blog:', error);
        }
    };

    const [password, setPassword] = useState('');
    const [teamId, setTeamId] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPopup, setShowPopup] = useState(false);

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleTeamIdChange = (e) => {
        setTeamId(e.target.value);
    };

    const handleAuthentication = () => {
        const validCredentials = [
            { loginId: 'Anshul@*TeamCodeINBlogs', teamCode: 'TeamCodeINBlogs' },
            { loginId: 'Mayur@*TeamCodeINBlogs', teamCode: 'TeamCodeINBlogs' },
            { loginId: 'Jyoti@*TeamCodeINBlogs', teamCode: 'TeamCodeINBlogs' },
            { loginId: 'Simarjot@*TeamCodeINBlogs', teamCode: 'TeamCodeINBlogs' },
            { loginId: 'Sukhman@*TeamCodeINBlogs', teamCode: 'TeamCodeINBlogs' },
            { loginId: 'Brahamjot@*TeamCodeINBlogs', teamCode: 'TeamCodeINBlogs' },
            { loginId: 'Admin@*TeamCodeINBlogs', teamCode: 'TeamCodeINBlogs' },
        ];

        const isValidCredentials = validCredentials.some(
            (cred) => cred.loginId === password && cred.teamCode === teamId
        );

        if (isValidCredentials) {
            setIsAuthenticated(true);
            setShowPopup(false);
        } else {
            setShowPopup(true);
            console.log('Incorrect login credentials');
        }
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
    };

    useEffect(() => {
        // Simulate blocking access based on IP after wrong password attempt
        const blockIPAfterWrongAttempt = async () => {
            if (showPopup) {
                try {
                    const response = await axios.get('https://api64.ipify.org?format=json');
                    const ipAddress = response.data.ip;
                    console.log('Blocked IP Address:', ipAddress);
                    // Implement IP blocking logic here, e.g., store blocked IP and set expiration time
                } catch (error) {
                    console.error('Error getting IP address:', error);
                }
            }
        };

        blockIPAfterWrongAttempt();
    }, [showPopup]);

    return (
        <div className="max-w-3xl mx-auto p-4 text-white">
            {!isAuthenticated ? (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-6 rounded shadow-lg">
                        <h2 className="text-3xl font-semibold mb-4 text-gray-500">Enter Login Credentials</h2>
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Login ID"
                            onChange={handlePasswordChange}
                            required
                            className="p-3 border rounded w-full mb-4 text-gray-500"
                        />
                        <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Team Code"
                            onChange={handleTeamIdChange}
                            required
                            className="p-3 border rounded w-full mb-4 text-gray-500"
                        />
                        <div className="flex items-center justify-between mb-4">
                            <label className="text-sm text-gray-600">
                                <input
                                    type="checkbox"
                                    checked={showPassword}
                                    onChange={() => setShowPassword(!showPassword)}
                                    className="mr-1"
                                />
                                Show Password
                            </label>
                        </div>
                        <button
                            onClick={handleAuthentication}
                            className="bg-blue-500 text-white rounded p-3 w-full hover:bg-blue-600"
                        >
                            Authenticate
                        </button>
                        {showPopup && (
                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
                                <div className="bg-white p-6 rounded shadow-lg">
                                    <h2 className="text-2xl font-semibold mb-4 text-gray-500">Access Blocked</h2>
                                    <p className="text-gray-500">Your access to the admin dashboard is blocked.</p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

            ) : (
                <>
                    <h2 className="text-3xl font-semibold mb-4">Upload an Event</h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <input
                            type="text"
                            name="title"
                            placeholder="Title"
                            onChange={handleInputChange}
                            required
                            className="p-3 border rounded w-full text-gray-500"
                        />
                        <textarea
                            name="description"
                            placeholder="Description"
                            onChange={handleInputChange}
                            required
                            className="p-3 border rounded w-full text-gray-500"
                        ></textarea>
                        <input
                            type="text"
                            name="speakerName"
                            placeholder="Speaker Name"
                            onChange={handleInputChange}
                            required
                            className="p-3 border rounded w-full text-gray-500"
                        />
                        <input
                            type="text"
                            name="topic"
                            placeholder="Topic"
                            onChange={handleInputChange}
                            required
                            className="p-3 border rounded w-full text-gray-500"
                        />
                        <input
                            type="text"
                            name="eventLink"
                            placeholder="Event URL"
                            onChange={handleInputChange}
                            required
                            className="p-3 border rounded w-full text-gray-500"
                        />
                        <input
                            type="file"
                            name="eventImage"
                            accept="image/*"
                            onChange={handleFileChange}
                            required
                            className="p-3 border rounded w-full text-grey-500"
                        />
                        <button
                            type="submit"
                            className="bg-blue-500 text-white rounded p-3 w-full hover:bg-blue-600"
                        >
                            Upload Event
                        </button>
                    </form>

                    <h2 className='text-3xl mt-8'>Upload Community</h2>
                    <form onSubmit={handleSubmit1} className="space-y-4">
                        <input
                            type="file"
                            name="communityImage"
                            accept="image/*"
                            onChange={handleFileChange1}
                            required
                            className="p-3 border rounded w-full"
                        />
                        <button
                            type="submit"
                            className="bg-blue-500 text-white rounded p-3 w-full hover:bg-blue-600"
                        >
                            Upload Community
                        </button>
                    </form>

                    <div className="mt-8">
                        <DeleteEvent />
                        <AdminPanel />
                        <CreateTestimonials />
                        <AddTeamMember />
                    </div>

                    <button
                        onClick={handleLogout}
                        className="bg-red-500 text-white rounded p-3 mt-4 hover:bg-red-600"
                    >
                        Logout
                    </button>
                </>
            )}
        </div>
    );
};

export default Admin;

