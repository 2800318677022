import React from 'react';

const Footer = ({ theme }) => {
    const isLightTheme = theme === 'light';
    const currentYear = new Date().getFullYear();

    return (
        <footer className={`border-t ${isLightTheme ? 'border-gray-200' : 'border-gray-700'}`}>
            <div className="text-center mb-3 mt-2">
                <a href="#" className="flex justify-center items-center mb-5 text-2xl font-semibold text-gray-900 dark:text-white">
                    <img src="https://www.codeinblogs.co/static/media/logo.60eb091ff96bf03a4987.png" className="h-16" alt="" />
                </a>
                <span className={`block text-sm text-center ${isLightTheme ? 'text-gray-500' : 'text-gray-400 dark:text-gray-400'}`}>
                    © 2022- {currentYear} <a href="https://codeinblogs.co" className="hover:underline">{isLightTheme ? 'CodeINBlogs' : 'CodeINBlogs'}</a>. All Rights Reserved.
                </span>
            </div>
        </footer>
    );
};

export default Footer;
