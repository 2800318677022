import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, RedditShareButton, TelegramShareButton } from 'react-share';
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon, RedditIcon, TelegramIcon } from 'react-share';
import Footer from './HomePage/Footer';

const BlogDetails = () => {
    const [blog, setBlog] = useState(null);
    const { id } = useParams();
    const [blogs, setBlogs] = useState([]);
    const [theme, setTheme] = useState('dark');
    const [showShareIcons, setShowShareIcons] = useState(false);

    const toggleTheme = () => {
        setTheme((prevTheme) => (prevTheme === 'dark' ? 'light' : 'dark'));
    };

    const toggleShareIcons = () => {
        setShowShareIcons(!showShareIcons);
    };

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/v1/blog/displaySpecificBlog/${id}`
                );
                setBlog(response.data);
            } catch (error) {
                console.error('Error fetching blog details:', error);
            }
        };

        fetchBlog();
    }, [id]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_URL}/api/v1/blog/displayBlog`
                );
                setBlogs(response.data);
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);

    const displayedBlogs = blogs.slice(0, 4);

    if (!blog) {
        return <div>Loading...</div>;
    }

    const formatDate = (dateString) => {
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const shareUrl = `${window.location.origin}/blog/${blog.title}`;


    return (
        <>
            <main className={`pt-8 pb-16 lg:pt-0 lg:pb-0 ${theme === 'light' ? 'bg-white' : 'bg-gray-900'} antialiased`}>
                <div className="flex justify-end p-4">
                    <button onClick={toggleTheme} className="focus:outline-none">
                        <img
                            src={theme === 'light' ? 'https://www.svgrepo.com/show/381213/dark-mode-night-moon.svg' : 'https://i.imgur.com/SaqwcdC.png'}
                            alt={theme === 'light' ? 'Switch to Dark Theme' : 'Switch to Light Theme'}
                            className="w-6 h-6"
                        />
                    </button>
                </div>
                <div className="flex justify-between px-4 mx-auto max-w-screen-xl py-3 ">
                    <article className={`mx-auto w-full max-w-2xl format format-sm sm:format-base lg:format-lg format-${theme === 'light' ? 'normal' : 'invert'}`}>
                        <header className={`mb-4 lg:mb-6 not-format ${theme === 'light' ? 'text-black' : 'text-white'}`}>
                            <address className="flex items-center mb-6 not-italic">
                                <div className="inline-flex items-center mr-3 text-sm">
                                    <img
                                        className="mr-4 w-16 h-16 rounded-full ring-2 ring-gray-300 dark:ring-gray-500 custom-blogpage-avatar-p"
                                        src='https://icons.iconarchive.com/icons/papirus-team/papirus-status/512/avatar-default-icon.png'
                                        alt={blog.authorName}
                                    />
                                    <div>
                                        <a href="#" rel="author" className={`text-xl font-bold ${theme === 'light' ? 'text-black' : 'text-white'}`}>{blog.authorName}</a>
                                        <p className={`text-base ${theme === 'light' ? 'text-gray-800' : 'text-gray-400'}`}>
                                            <time pubdate datetime="2022-02-08">{formatDate(blog.createdAt)}</time>
                                        </p>
                                        <p>Views: {blog.visitors}</p>
                                    </div>
                                </div>
                                <div className={`share-button flex items-center ${theme === 'light' ? 'bg-gray-100 border-[1px] rounded-sm border-[#ccc]' : 'bg-gray-800 border-[1px] rounded-sm border-[#ccc]'}`} onClick={toggleShareIcons}>
                                    {theme === 'light' ? (
                                        // Light theme icon
                                        <svg viewBox="0 0 24 24" fill="none" className='w-6 h-6' xmlns="http://www.w3.org/2000/svg" stroke="#000000">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z" fill="#ffffff"></path>
                                            </g>
                                        </svg>
                                    ) : (
                                        // Dark theme icon
                                        <svg viewBox="0 0 24 24" fill="currentcolor" className='w-6 h-6' xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z" fill="#000000"></path>
                                            </g>
                                        </svg>
                                    )}
                                    <div className={`icons py-5 ${showShareIcons ? 'show-icons space-x-4 opacity-100 transition-opacity duration-500 ease-in' : 'opacity-0 transition-opacity duration-500 ease-out'} ${theme === 'light' ? 'bg-gray-100 border-[1px] rounded-sm border-[#ccc]	' : 'bg-gray-800 border-[1px] rounded-sm border-[#ccc]'}`}>
                                        <FacebookShareButton url={shareUrl} className='mt-2 my-2' quote={blog.title}>
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <TwitterShareButton url={shareUrl} className='mt-2 my-2' title={blog.title}>
                                            <TwitterIcon size={32} round />
                                        </TwitterShareButton>
                                        <LinkedinShareButton url={shareUrl} className='mt-2 my-2' title={blog.title}>
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>
                                        <WhatsappShareButton url={shareUrl} className='mt-2 my-2' title={blog.title}>
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                        <RedditShareButton url={shareUrl} className='mt-2 my-2' title={blog.title}>
                                            <RedditIcon size={32} round />
                                        </RedditShareButton>
                                        <TelegramShareButton url={shareUrl} className='mt-2 my-2' title={blog.title}>
                                            <TelegramIcon size={32} round />
                                        </TelegramShareButton>
                                    </div>
                                </div>
                            </address>
                            <h1 className={`mb-4 text-3xl font-extrabold leading-tight lg:mb-6 lg:text-4xl ${theme === 'light' ? 'text-black' : 'text-white'}`}>{blog.title}</h1>
                        </header>
                        <div className={`${theme === 'light' ? 'text-black' : 'text-white'}`} dangerouslySetInnerHTML={{ __html: blog.description }} />
                    </article>
                </div>
                <div className="flex flex-col items-center justify-center px-4 mx-auto max-w-screen-xl py-3 ">
                    <p className={`text-lg font-semibold mb-2 ${theme === 'light' ? 'text-gray-800' : 'text-gray-400'}`}>Share this blog at:</p>
                    <div className="flex items-center flex-wrap space-x-4 justify-center">
                        <FacebookShareButton url={shareUrl} quote={blog.title}>
                            <FacebookIcon size={32} round />
                        </FacebookShareButton>
                        <TwitterShareButton url={shareUrl} title={blog.title}>
                            <TwitterIcon size={32} round />
                        </TwitterShareButton>
                        <LinkedinShareButton url={shareUrl} title={blog.title}>
                            <LinkedinIcon size={32} round />
                        </LinkedinShareButton>
                        <WhatsappShareButton url={shareUrl} title={blog.title}>
                            <WhatsappIcon size={32} round />
                        </WhatsappShareButton>
                        <RedditShareButton url={shareUrl} title={blog.title}>
                            <RedditIcon size={32} round />
                        </RedditShareButton>
                        <TelegramShareButton url={shareUrl} title={blog.title}>
                            <TelegramIcon size={32} round />
                        </TelegramShareButton>
                    </div>
                </div>
                <aside aria-label="Related articles" className={`py-8 lg:py-10 ${theme === 'light' ? 'bg-gray-100' : 'bg-gray-800'}`}>
                    <div className="px-4 mx-auto max-w-screen-xl">
                        <h2 className={`mb-8 text-2xl font-bold ${theme === 'light' ? 'text-gray-800' : 'text-white'}`}>Related articles</h2>
                        <div className="grid gap-12 sm:grid-cols-2 lg:grid-cols-4">
                            {displayedBlogs.map((blog) => (
                                <div key={blog._id} className={`border-solid border-2 ${theme === 'light' ? 'border-gray-300' : 'border-sky-500'} rounded-lg p-1 ${theme === 'light' ? 'bg-white' : 'bg-sky-950'} text-center`}>
                                    <article className="max-w-xs">
                                        <Link to={`/blog/${blog.title}`} key={blog._id}>
                                            <img src={`data:image/jpeg;base64,${blog.profileImage}`} className="h-40 mb-5 rounded-lg w-full" alt="Image 1" />
                                        </Link>
                                        <h2 className={`mb-2 text-xl font-bold leading-tight ${theme === 'light' ? 'text-black' : 'text-white'}`}>
                                            <a href="#">{blog.title}</a>
                                        </h2>
                                        <p className={`mb-4 ${theme === 'light' ? 'text-gray-800' : 'text-gray-200'}`}>
                                            <div dangerouslySetInnerHTML={{ __html: blog.description.substring(0, 100) }} />
                                        </p>
                                        <Link to={`/blog/${blog._id}`} key={blog._id} className={`inline-flex items-center font-medium underline-offset-4 ${theme === 'light' ? 'text-blue-500' : 'text-white hover:no-underline'}`}>
                                            by {blog.authorName} on {formatDate(blog.createdAt)}
                                        </Link>
                                    </article>
                                </div>
                            ))}
                        </div>
                    </div>
                </aside>
            </main>
            <Footer theme={theme} />
        </>
    );
};

export default BlogDetails;
